body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
  /* SIDEBAR CONTROL */
  
  
  .sidebar_control {
    transition: all .2s;
    position: fixed;
    width: 320px;
    left: 0;
    top: 0;
    z-index: 99999999;
    background-color: white;
    height: 100vh;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border: 2px solid rgba(213, 213, 213, 0.53);
  }

  .sidebar_control_close{
    transition: all .2s;
    position: fixed;
    width: 320px;
    left: -30000px;
    top: 0;
    z-index: 99999999;
    background-color: white;
    height: 100vh;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border: 2px solid rgba(213, 213, 213, 0.53);
  }

  .header_sidebar_control{
    display: flex;
    justify-content: center;
  }
  
  .header_sidebar_control>img {
    
    margin-top: 0.3rem;
    width: 110px;
    object-fit: contain;
    border-radius: 100%;
    height: 110px;
    margin-bottom: 1rem;
  }
  
  /* COLLAPSE CONTROL */
  
  .btn_collapse {
    display: flex;
    padding-top: 1rem;
    font-family: "Poppins", system-ui;
    font-weight: 500;
    font-style: normal;
    border-radius: 5px;
    align-items: center;
    width: 100%;
    font-size: 1.0rem;
    height: 68px;
    background-color: white;
    border: 1px solid rgba(213, 213, 213, 0.53);
    ;
  }
  
  .flex_btn_collapse {
    display: flex;
    padding-top: 0.4rem;
    width: 100%;
    align-items: center;
    
    justify-content: space-around;
  }
  
  .flex_btn_collapse_sub {
    display: flex;
    width: 100%;
    z-index: 9999999;

    justify-content: space-around;
  }



  
  .collapse > .card {
    min-height: 67px;
    border: 1px solid rgba(213, 213, 213, 0.53);
    height: 60px;
  }
  
  .card-body{
    border: 1px solid rgba(213, 213, 213, 0.53);
    height: 60px; 
    min-height: 67px;

  }

  
  
  .flex_btn_collapse_sub > div > p {
    position: relative;
    white-space: wrap;
    overflow-x: hidden;
    width: 150px;
    top: 5px;
    font-family: "Poppins", system-ui;
    font-weight: 500;
    margin-left: 0.3rem;
    font-style: normal;
    color: rgb(37, 37, 37);
    font-size: 1.0rem;
  }
  
  .flex_btn_collapse_sub>img {
    width: 30px;
    height: 30px;
  }
  
  .flex_btn_collapse_sub>div {
    display: flex;
    height:40px;
    align-items: center;
    width: 100%;
  }

  
  .flex_btn_collapse_sub>div>img {
    width: 50px;
    object-fit: contain;
    border-radius: 100%;
    margin-left: 0.4rem;
    margin-right: 0.9rem;
    height: 50px;
  }
  
  .icon_flex_collapse {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-top: -0.9rem;
  }
  
  .flex_btn_collapse> p {
    margin-top: 0rem;

    font-size: 0.9rem;
    font-weight: 500;
  }

  
  .flex_btn_collapse>div>p {
    font-size: 0.9rem;
  }
  
  
  
  .flex_btn_collapse>svg {
    margin-top: -0.9rem;
    margin-right: 0;
  }
  
  .body_sidebar_control {
    height: 70vh;
    overflow-y: auto;
  }
  
  
  ::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.33);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar {
    width: 8.5px;
    height: 15px;
    background: #d8d8d8;
    border-radius: 50px;
  }
  
  .input_sidebar {
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  .input_sidebar>input {
    border: 1px solid #d8d8d8;
    width: 90%;
    height: 36px;
    font-size: 0.9rem;
    border-radius: 5px;
    margin-bottom: 0.9rem;
    outline: none;
    font-family: "Poppins", system-ui;
    padding-left: 0.9rem;
  }
  
  .input_sidebar>input::placeholder {
    font-family: "Poppins", system-ui;
    font-style: normal;
    color: #6f6f6f;
  }
  
  .input_sidebar>svg {
    position: absolute;
    right: 25px;
    top: 2px;
  }
  
  .header_sidebar_control>svg {
    position: absolute;
    right: 15px;
    top: 19px;
  }
  
  .container_flex_collapse {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  
  .circle_collapse {
    position: absolute;
    padding-top: 0.2rem;
    width: 20px;
    top: calc(-15%);
    right: calc(-20%);
    height: 20px;
    border-radius: 100px;
    color: white;
    font-size: 0.6rem;
    background-color: black;
  }
  
  @media screen and (max-width:720px) {
  
    .sidebar_control {
      position: fixed;
      width: 90%;
      height: 100%;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border: 2px solid rgba(213, 213, 213, 0.53);
    }
  
  
  }