

@font-face {
    font-family: "Poppins-Bold";
    src: url(../fonts/Poppins/Poppins-Bold.ttf);
}


@font-face {
    font-family: "Poppins-Medium";
    src: url(../fonts/Poppins/Poppins-Medium.ttf);
}


@media screen and (max-width:720px) {

  .container_sidebar_mobile_open {
    transition: all .2s ease-in;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: white;
    width: 266px;
    padding-top: 46px;
    left: 0px;
    z-index: 9999;
    height: 100%;
    position: fixed;
  }
  .container_sidebar_mobile {
    transition: all .2s ease-in;
    background-color: white;
    width: 266px;
    padding-top: 46px;
    padding-left: 32px;
    left: -300px;
    z-index: 9999;
    height: 100vh;
    position: fixed;
  }

  .sidebar_open_background {
    transition: all .2s ease-in;
    background-color: rgba(0, 0, 0, 0.142);
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
  }

  .container_options_sidebar_mobile {
    padding-left: 22px;
  }

  .company_icon_sidebar_mobile {
    width: 190px;
  }

  .container_options_sidebar_mobile {
    margin-top: 2.2rem;
  }

  .container_options_sidebar_mobile>div {
    margin-top: 27px;
  }

  .not_check_option_sidebar_exit{
    position: absolute;
    bottom:35px;
  }

  
  .not_check_option_sidebar>p {
    margin-top: 1rem;
    color: rgb(97, 97, 97);
    margin-left: 0.8rem;
  }

  .not_check_option_sidebar_exit > img{
    width: 75px;
  }

  .check_option_sidebar {
    width: 89%;
    display: flex;
    align-items: center;
    color: white;

    padding: 10px;
    font-family: "Poppins-Medium";
    border-radius: 5px;
    height: 43px;
    background-color:rgba(0, 0, 0, 0.815);
  }

  .not_check_option_sidebar{
    width: 220px;
    display: flex;
    align-items: center;
    color: white;
    padding: 10px;
    font-family: "Poppins-Medium";
    border-radius: 5px;
    height: 39px;
  }


  .check_option_sidebar>p {
    margin-top: 0.9rem;
    margin-left: 0.5rem;
  }

  .check_option_sidebar > img{
    width: 20px;
    height: 20px;
  }


  .not_check_option_sidebar > img{
    width: 20px;
    height: 20px;
  }
    
}