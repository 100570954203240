
.filter_product_close_horizontal {
    transition: all .2s ease-in-out;
    margin-top: 0rem;
    margin-right: 0.9rem;
    top: calc(40%);
    z-index: 9999;
    background-color: white;
    cursor: pointer;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    justify-content: space-between;
    border-radius: 3px;
    width: 90px;
    height: 53px;
    border: 1px solid rgb(165, 165, 165);
  }
  
  .filter_product_close_horizontal>p {
    display: none;
  }
  
  .filter_product_close_horizontal>img {
    margin: 0 auto;
    transition: all .2s;
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  
  .filter_product_close {
    transition: all .2s ease-in-out;
    margin-top: 2rem;
    margin-left: 2rem;
    top: calc(40%);
    z-index: 9999;
    background-color: white;
    cursor: pointer;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    justify-content: space-between;
    border-radius: 3px;
    width: 90px;
    height: 53px;
    border: 1px solid rgb(165, 165, 165);
  }
  
  .filter_product_close>p {
    display: none;
  }
  
  .filter_product_close>svg {
    margin: 0 auto;
    transition: all .2s;
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  
  