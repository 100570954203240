
.container_not_found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_not_found > img{
  width: 450px;
}

.container_not_found > p {
  font-family: "OpenSans-Medium";
  color: black;
  font-size: 1.1rem;
}

.container_flex_products{
  display: flex;
  flex: 1;
}

.container_row_products {
    max-width: 1140px;
    margin: 2.5rem auto;
    display: flex;
    flex-direction: column;
  }

  .dropdown-item{
    font-family: "OpenSans-Medium";

    color: white;
  }

  
  .container_row_products_banner>img {
    width: 100%;
    object-fit: contain;
  }

  .sidebar_filter_products{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 130px;
    height: 100%;
    box-shadow: 0 0 5px black;
  }
  

  
.container_flex_row_product {
    max-width: 1420px;
    margin: 0 auto;
    
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .flex_row_products_animated {
    transition: all .2s;
  
    padding-left: 9rem;
    max-width: 1190px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .flex_row_products {
    transition: all .2s;
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  
  .filter_product_horizontal {
    transition: all .2s ease-in;
    margin-top: 0rem;
    top: calc(40%);
    z-index: 9999;
    background-color: white;
    cursor: pointer;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    justify-content: space-between;
    border-radius: 3px;
    width: 150px;
    height: 53px;
    border: 1px solid rgb(165, 165, 165);
  }
  
  .filter_product_horizontal>p {
    font-size: 1.1rem;
    height: 9px;
    font-family: "OpenSans-Medium";
  }
  
  .filter_product_horizontal>svg {
    transition: all .2s ease-in-out;
  
    margin: 0 auto;
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  
  @media screen and (max-width:720px){

    .flex_row_products > div {
      width: 86%;
    }
    
  }